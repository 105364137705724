import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="IBM Carbon Website Template | Cloud Web Template | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/ibm-carbon-cloud-dashboard-web-app/"
    metaDescription="Whatever project you're working on, our cloud web app template framed using IBM Carbon Design System is the perfect choice for you."
    description="
    h2:A cloud website template for designing a software application using IBM Carbon Design System
    <br/>
    Carbon Cloud Dashboard is a <a:https://uizard.io/templates/>UI template</a> that you can use when you want to design a software application. This web app template reflects the IBM Carbon Design System, which is the open-source official design system for IBM web. The Carbon Design System uses a series of unique styles, components, and guidelines that altogether create a unified user interface, all of which are reflected in this template.
    <br/>
    h3:Design a software application, an app, or a website with the Carbon Design System
    <br/>
    Whether you are designing a software application, a mobile app, or a website, our cloud web app template framed using IBM Carbon Design System is the perfect choice for your project. Design your software application <a:https://uizard.io/prototyping/>UX prototype</a> with Uizard now and share it with your colleagues and teammates. Collaboration has never been so easy.
    <br/>
    h3:A perfect design space with endless customization
    <br/>
    Carbon Cloud Dashboard features a sleek and minimalistic design for your next digital product, dashboard project, or SaaS software. It's clean and simple and offers a wide array of customization options allowing you to realize any design on a screen in front of you. Join the digital product design revolution with Uizard today.
    "
    pages={[
      "A software homepage",
      "A settings and user preferences page",
      "A dashboard page",
    ]}
    projectCode="PjYKlxAbAECjAjbwgJrP"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of IBM Carbon Cloud Dashboard Web App: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of IBM Carbon Cloud Dashboard Web App: dashboard page"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of IBM Carbon Cloud Dashboard Web App: home page"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of IBM Carbon Cloud Dashboard Web App: settings page"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of IBM Carbon Cloud Dashboard Web App template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/ibm-carbon-cloud-dashboard/IBM-Carbon-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/ibm-carbon-cloud-dashboard/IBM-Carbon-Dashboard.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/ibm-carbon-cloud-dashboard/IBM-Carbon-Home.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/ibm-carbon-cloud-dashboard/IBM-Carbon-Settings.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/ibm-carbon-cloud-dashboard/IBM-Carbon-Summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
